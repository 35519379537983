import React, { useEffect, useState } from "react";
import { ProductData } from "../TemporaryData/TemporaryData";
import ProductCard from "../Cards/ProductCard";
import Pic1 from "../Assets/Pic1.jpg";
import RecommendedCard from "../Cards/RecommendedCard";
import SearchIcon from "../Assets/Search.png";
import { useNavigate } from "react-router-dom";
import SaleBanner from "../Assets/SaleBanner.jpg";
import Header from "../Components/Header";
import MainLogoIcon from "../Assets/MainLogo.png";
import CloseIcon from "../Assets/CloseIcon.png";
function LoginPage() {
  const navigate = useNavigate();
  useEffect(()=>
  {
    window.addEventListener("unload",(e)=>
      {
        e.preventDefault();
      })
  },[]);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="flex flex-row w-[100%] h-full self-center">
        <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] w-0 flex flex-col h-full justify-center items-center">
          <img src={SaleBanner} className="w-full h-full object-fill" />
        </div>
        <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] w-full flex flex-col h-full justify-around items-center">
          <div className="flex flex-row 2xl:w-0 xl:w-0 lg:w-0 w-[90%] self-center  justify-between items-center">
            <img
              src={CloseIcon}
              className="w-[25px] h-[25px] object-contain opacity-0"
            />
            <img
              src={MainLogoIcon}
              className="w-[200px] h-[40px] object-contain"
            />
            <img
              src={CloseIcon}
              className="w-[25px] h-[25px] object-contain cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="w-full  2xl:gap-y-[20px] xl:gap-y-[20px] lg:gap-y-[20px] gap-y-[20px] justify-between items-center  flex flex-col">
            <div className="text-2xl font-semibold text-[#36454F] text-center">
              Sign in / Register
            </div>
            <input
              type="text"
              placeholder="Email"
              className="2xl:w-[40%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[15px] xl:py-[15px] lg:py-[15px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            <input
              type="password"
              placeholder="Password"
              className="2xl:w-[40%] xl:w-[40%] lg:w-[40%] w-[90%] 2xl:py-[15px] xl:py-[15px] lg:py-[15px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            <button className="2xl:w-[40%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[15px] xl:py-[15px] lg:py-[15px] py-[7px]  bg-black text-2xl text-white outline-none px-[10px] font-bold">
              CONTINUE
            </button>
            <div className="text-[#36454F] text-center text-base">
              Your privacy will be well protected,
              <br />
              please refer our{" "}
              <span className="text-[#0000EE] cursor-pointer">
                Privacy Policy
              </span>
              .
            </div>
          </div>
          <div className="flex flex-row 2xl:w-0 xl:w-0 lg:w-0 w-[90%] self-center justify-between items-center opacity-0">
            <img
              src={MainLogoIcon}
              className="w-[200px] h-[40px] object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
