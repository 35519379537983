import {createSlice} from '@reduxjs/toolkit';
export const CustomersSlice = createSlice({
  name: 'customers',
  initialState: {
    value: [],
  },
  reducers: {
    populate_customers: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_customers} = CustomersSlice.actions;
export default CustomersSlice.reducer;
