import {configureStore} from '@reduxjs/toolkit';
import ProductReducer from './ProductsSlice';
import BannerReducer from './BannersSlice';
import CustomerReducer from './CustomersSlice';
import OrderReducer from './OrdersSlice';
import CategoryReducer from './CategoriesSlice';
import MetadataReducer from './MetadataSlice';
import PromotionalTextReducer from './PromotionalTextSlice';
import PolicyReducer from './PolicySlice';
export const store = configureStore({
  reducer: {
    callCategory: CategoryReducer,
    callProduct: ProductReducer,
    callBanner: BannerReducer,
    callCustomer: CustomerReducer,
    callOrder: OrderReducer,
    callMetadata: MetadataReducer,
    callPromotionalText:PromotionalTextReducer,
    callPolicy:PolicyReducer
  },
});
