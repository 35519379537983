import React, { useState, useEffect, useRef } from "react";
import BannerSlider from "../Components/BannerSlider";
import { CategoryData } from "../TemporaryData/TemporaryData";
import CategoryCard from "../Cards/CategoryCard";
import ProfileImageIcon from "../Assets/Profile.png";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";
import WhatsappIcon from "../Assets/WhatappIcon.png";
import Cart from "../Components/Cart";
import RecommendedCard from "../Cards/RecommendedCard";
import { ProductData } from "../TemporaryData/TemporaryData";
import { populate_categories } from "../Redux/CategoriesSlice";
import {useSelector,useDispatch} from 'react-redux';
import {db} from '../Components/FirebaseInit.js'
import { collection,getDocs,query,where } from "firebase/firestore"; 
import { populate_products } from "../Redux/ProductsSlice";
function Categories({ props }) {
  const categories=useSelector((state)=>state.callCategory.value);
  const products=useSelector((state)=>state.callProduct.value);
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const [showCart, setShowCart] = useState(false);
  useEffect(()=>
  {
    if(categories.length==0)
    {
      getTheCategories();
    }
  },[]);
  const getTheCategories=async()=>
  {
    const querySnapshot = await getDocs(collection(db, "categories"));
    let tempCategory=[];
    querySnapshot.forEach((doc) => {
        tempCategory.push({
                id:doc.id,
                name:doc.data().name,
                description:doc.data().description,
                iconUrl:doc.data().iconUrl,
                isActive:doc.data().isActive,
               createdAt:new Date(doc.data().createdAt.seconds*1000).getFullYear()+"-"+(new Date(doc.data().createdAt.seconds*1000).getMonth()+1)+"-"+new Date(doc.data().createdAt.seconds*1000).getDate()+" "+new Date(doc.data().createdAt.seconds*1000).getHours()+":"+new Date(doc.data().createdAt.seconds*1000).getMinutes()+":"+new Date(doc.data().createdAt.seconds*1000).getSeconds()
            });
        });
    dispatch(populate_categories(tempCategory));
  }
  const getTheProductsAndNavigate=async(categoryId)=>
  {
    if(products.filter(e=>e.associatedCategory==categoryId).length>0)
    {
      navigate(`/products/${categoryId}`)
    }
    else
    {
      
      const q=query(collection(db,"products"),where("associatedCategory","==",categoryId));
      const querySnapshot = await getDocs(q);
      let tempProducts=[];
      querySnapshot.forEach((doc) => {
          tempProducts.push({
            id:doc.id,
            name:doc.data().name,
            description:doc.data().description,
            associatedCategory:doc.data().associatedCategory,
            cost:doc.data().cost,
            discount:doc.data().discount,
            pictures:doc.data().pictures,
            inStock:doc.data().inStock,
            availableColors:doc.data().availableColors,
            availableSizes:doc.data().availableSizes,
           createdAt:new Date(doc.data().createdAt.seconds*1000).getFullYear()+"-"+(new Date(doc.data().createdAt.seconds*1000).getMonth()+1)+"-"+new Date(doc.data().createdAt.seconds*1000).getDate()+" "+new Date(doc.data().createdAt.seconds*1000).getHours()+":"+new Date(doc.data().createdAt.seconds*1000).getMinutes()+":"+new Date(doc.data().createdAt.seconds*1000).getSeconds()
              });
          });
      dispatch(populate_products(tempProducts));
      navigate(`/products/${categoryId}`)

    }
  }
  return (
    <div className={` flex flex-col w-full justify-between h-screen `}>
      <Header props={{ onPressCart: () => setShowCart(true) }} />
      <div
        className={`flex flex-col self-center w-full h-[87vh] overflow-y-auto bg-[#F5F5F5] items-center `}
      >
        <div className="grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 grid-cols-2 gap-y-[5px] w-full ">
          {categories.map((item, index) => (
            <div
              key={index}
              className="flex flex-col h-[130px]  items-center justify-center"
            >
              <CategoryCard
                props={{
                  cardWidth: "95%",
                  name: item.name,
                  ageGroup: item.description,
                  categoryImage: item.iconUrl,
                  onClickTheCategory: () => getTheProductsAndNavigate(item.id),
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex flex-col w-screen px-[10px]">
          <div className=" text-black  font-semibold text-lg">New Arrivals</div>
          <div className="flex flex-row w-full overflow-x-auto  gap-x-[20px] mt-[10px] ">
            {ProductData.map((item, index) => (
              <RecommendedCard
                key={index}
                props={{
                  productImage: item.thumbnailUrl,
                  productName:
                    item.productName.length > 40
                      ? item.productName.substring(0, 41) + "..."
                      : item.productName,
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {showCart == true ? (
        <Cart props={{ closeButtonAction: () => setShowCart(false) }} />
      ) : null}
      <img
        src={WhatsappIcon}
        className="w-[50px] h-[50px] object-contain absolute bottom-[5px] right-[5px] cursor-pointer"
        onClick={()=>window.open(`https://wa.me/85251136004`)}
      />
    </div>
  );
}
export default Categories;
