import React,{useState,useEffect} from 'react';
import {store} from './Redux/store.js';
import {Provider} from 'react-redux';
import RoutingComponent from './Components/RoutingComponent.js';


const App = () => {

  return (
    <Provider store={store}>
      <RoutingComponent />
    </Provider>
  );
};
export default App;