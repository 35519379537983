import React from "react";
import CloseIcon from "../Assets/CloseIcon.png";
import DeliveryIcon from "../Assets/DeliveryIcon.png";
import ProductCard from "../Cards/ProductCard";
import CartCard from "../Cards/CartCard";
import { ProductData } from "../TemporaryData/TemporaryData";

function Cart({ props }) {
  return (
    <div className="w-screen h-screen flex flex-col absolute items-end z-[500]">
      <div className="w-screen h-screen bg-black opacity-75 absolute" />
      <div className="2xl:w-[30%] xl:w-[30%] lg:w-[30%] w-[90%] flex flex-col h-full bg-white border-2 border-black absolute">
        <div className="w-[95%] flex flex-row justify-around self-center items-center py-[10px]">
          <img
            src={CloseIcon}
            className="w-[20px] h-[20px] object-contain opacity-0 cursor-pointer"
          />
          <div className="text-xl text-black font-semibold">My Bag (3)</div>
          <img
            src={CloseIcon}
            className="w-[20px] h-[20px] object-contain cursor-pointer"
            onClick={() => {
              props.closeButtonAction();
            }}
          />
        </div>
        <div className="w-full flex flex-col bg-gray-200 p-[5px]">
          <div className="flex flex-row w-full h-full items-center gap-x-[10px]">
            <img
              src={DeliveryIcon}
              className="w-[40px] h-[40px] object-contain"
            />
            <div className="text-[14px]">
              You now qualify for&nbsp;
              <span className="font-bold text-black ">Free Shipping!</span>
            </div>
          </div>
        </div>
        <div className="h-[70%] overflow-y-auto flex flex-col">
          {ProductData.map((item, index) => (
            <CartCard
              props={{
                productName: item.productName,
                productPrice: item.productPrice,
                isNew: item.isNew,
                starRating: item.starRating,
                thumbnailUrl: item.thumbnailUrl,
                isRecommended: false,
              }}
              key={index}
            />
          ))}
        </div>
        <div className="w-full border-t-[1px] border-t-gray-300" />
        <div className="w-[95%] self-center flex flex-row font-bold text-base text-black justify-between mt-[5px]">
          <div>Total</div>
          <div>HKD.234</div>
        </div>
        <button className="w-[95%] mt-[5px] self-center  2xl:py-[15px] xl:py-[15px] lg:py-[15px] py-[7px]  bg-black text-2xl text-white outline-none px-[10px] font-bold">
          CHECKOUT
        </button>
      </div>
    </div>
  );
}
export default Cart;
